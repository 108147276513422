import React from 'react'
import * as styles from "../../styles/main/common.module.scss"
import Header from "../main/header"
import Footer from "../main/footer"
import Body from "../main/body"
import Notifications from 'react-notify-toast';
export default function Common({ children }){
    return(
        <div className={styles.container}>
            <Notifications />
            <Header />
            <Body>
                {children}
            </Body>
            <Footer/>
        </div>
    )
}