import React from 'react'
import * as styles from '../../styles/minis/backgroundvideo.module.scss'
import VideoSource from '../../videos/typing.mp4'
const BackgroundVideo = () => {
    //const videoSource = "../../videos/typing.mp4";
    return (
        <div className={styles.container}>
            <video autoPlay="autoplay" loop="loop" muted className={styles.video}>
                <source src={VideoSource} type="video/mp4" />
            </video>
        </div>
    )
}

export default BackgroundVideo

