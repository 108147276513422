import * as React from "react"
import Common from "../components/main/common"
import BackgroundVideo from "../components/minis/backgroundvideo"
import SiteHeader from "../components/minis/siteheader"
import * as styles from "../styles/index.module.scss"
import { Link, navigate } from "gatsby"
const IndexPage = () => {
  return (
    <main>
        <title>The Casual Dev</title>
        <BackgroundVideo />
        <Common>
          <div className={styles.contentWrapper}>
            <SiteHeader style={styles.fontSize} onClick={
                event => {
                    event.preventDefault()
                    navigate("/")
                }
            }/>
            <div className={styles.tagline}>Just another awesome developer.</div>
            <Link to="/" className={styles.btn_projects}>
                Journal
            </Link>
          </div>
        </Common>    
    </main>
    
  )
}

export default IndexPage